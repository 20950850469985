<template>
  <div class="study" v-loading="pageLoading">
    <div class="studyHeader">
      <img
        :src="
          user.userface ||
          'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/default_avatar.png'
        "
        class="avatar"
        alt=""
      />
      <div class="user">
        <p class="name">{{ user.name }}</p>
      </div>
      <div class="time">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/time_icon.png"
          alt=""
        />
        累计学习
        <span>&nbsp;{{ studyInfo.total_time }}&nbsp;</span>
        分钟
      </div>
      <div class="data">
        <div class="item">
          <p class="value">{{ studyInfo.today_time }}</p>
          <p class="label">今日学习 (分钟)</p>
        </div>
        <div class="item">
          <p class="value">{{ studyInfo.day_number }}</p>
          <p class="label">连续学习 (天)</p>
        </div>
        <div class="item">
          <p class="value">{{ studyInfo.lesson_number }}</p>
          <p class="label">完成课程 (节)</p>
        </div>
      </div>
    </div>
    <div class="record">
      <div class="tab" v-if="plat!='119'">
        <p
          class="title"
          :class="{ studyActive: activeIndex == 1 }"
          @click="activeIndex = 1"
        >
          课程学习记录
        </p>
        <p
          class="title"
          :class="{ studyActive: activeIndex == 2 }"
          @click="activeIndex = 2"
        >
          培训学习记录
        </p>
      </div>
      <div class="tab" v-if="plat=='119'">
        <p
          class="title"
          :class="{ studyActive: activeIndex == item.category_id }"
          @click="changeList(item.category_id)"
          v-for="item in cateList"
          :key="item.category_id"
        >
          {{item.category_name}}
        </p>
        
      </div>
      <div class="list" v-if="plat!='119'">
        <van-list
          v-loading="listLoading"
          v-if="list.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div
            class="card"
            v-for="(item, index) of list"
            :key="index"
            @click="jumpDetail(item)"
          >
            <div class="content">
              <van-image class="cover" fit="cover" :src="item.cover">
                <template v-slot:error>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                  ></van-image>
                </template>
                <template v-slot:loading>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                  ></van-image>
                </template>
              </van-image>
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <!-- <p class="text" v-show="activeIndex == 1">
                  <span>共{{ item.total_lesson_number }}节</span> 已学{{ item.fen }}
                </p>
                <p class="text" v-show="activeIndex == 2">
                  <span>共{{ item.total_study_number }}学时</span> 已学{{
                    item.study_times
                  }}学时
                </p> -->
                <div class="handle">
                  <p>{{ item.lesson_name }}</p>

                  <div class="btn">去学习</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty style="margin: 0 auto" v-if="!list.length" description="暂无数据" />
      </div>
      <div class="list" v-if="plat=='119'">
        <van-list
          v-loading="listLoading"
          v-if="tuoyuList.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div
            class="card"
            v-for="(item, index) of tuoyuList"
            :key="index"
            @click="jumpDetail(item)"
          >
            <div class="content">
              <van-image class="cover" fit="cover" :src="item.cover">
                <template v-slot:error>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    :src="$tuoyuPlaceImg"
                  ></van-image>
                </template>
                <template v-slot:loading>
                  <van-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    :src="$tuoyuPlaceImg"
                  ></van-image>
                </template>
              </van-image>
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <!-- <p class="text">
                  <span>共{{ item.total_lesson_number }}节</span> 已学{{ item.fen }}
                </p>
                <p class="text" v-show="activeIndex == 2">
                  <span>共{{ item.total_study_number }}学时</span> 已学{{
                    item.study_times
                  }}学时
                </p> -->
                <div class="handle">
                  <p>{{ item.lesson_name }}</p>

                  <div class="btn">去学习</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty style="margin: 0 auto" v-if="!tuoyuList.length" description="暂无数据" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      tuoyuList:[],
      cateList: [
        {
          name: "课程学习记录",
          id: 1,
        },
        {
          name: "培训学习记录",
          id: 2,
        },
      ],
      user: {},
      activeIndex: 1,
      classify: 0,
      loading: false,
      pageLoading: true,
      finished: true,
      more: false,
      listLoading: false,
    };
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    window.addEventListener("scroll", this.onLoad);
    this.pageLoading = false;
    if (this.plat == "119") {
      this.$request.tuoyuCategory({ flat_id: this.plat }).then((res) => {
        this.cateList = res.data
        this.changeList(this.cateList[0].category_id)
      });
    }else{
      this.$store.dispatch("getStudy");
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    studyInfo() {
      return this.$store.state.studyInfo;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    list(e) {
      return this.activeIndex == 1 ? this.studyInfo.list : this.studyInfo.zi_list;
    },
  },
  methods: {
    changeList(id){
      this.activeIndex = id
      this.$request.tuoyuStudy({
        flat_id:this.plat,
        category_id:id
      }).then(res=>{
         this.tuoyuList = res.data.list
      })
    },
    jumpDetail(item) {
      if(this.plat == 119){
        this.$router.push(`./detail2?id=${item.goods_id}`);
      }else{
        this.$router.push(`./detail?id=${item.goods_id}&type=video`);
      }
      
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.study {
  min-height: calc(100vh - 230px);
  .studyHeader {
    width: 100%;
    height: 500px;
    background: #ffffff;
    border-radius: 50px 50px 0px 0px;
    margin-top: 116px;
    position: relative;
    padding-top: 90px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .avatar {
    position: absolute;
    top: -76px;
    left: 50%;
    transform: translateX(-50%);
    width: 152px;
    height: 15rpx;
    border-radius: 50%;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
  }

  .name {
    font-size: 38px;
    font-weight: 600;
    color: #333;
  }

  .time {
    font-size: 30px;
    font-weight: 400;
    color: #555550;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 35px;
  }

  .time > img {
    width: 30px;
    margin-right: 11px;
    margin-bottom: 6px;
  }

  .time > span {
    font-size: 52px;
    color: #333;
    font-weight: 500;
  }

  .data {
    width: 90%;
    height: 162px;
    background: #f7f8fa;
    border-radius: 12px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    box-sizing: border-box;
    text-align: center;
  }

  .data .value {
    color: #333;
    font-size: 42px;
    font-weight: 500;
  }

  .data .label {
    color: #555;
    font-size: 26px;
    margin-top: 10px;
  }

  .record {
    // height: calc(100vh - 708px);
    // background: #fff;
    .tab {
      padding-top: 20px;
      padding-bottom: 20px;
      .title {
        color: #333;
        font-size: 16px;
        padding: 0 30px;
        height: 70px;
        display: flex;
        align-items: center;
        display: inline;
        cursor: pointer;
      }
      .studyActive {
        font-weight: 600;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -15px;
          display: block;
          width: 63px;
          height: 5px;
          background: #a40001;
        }
      }
    }
  }
}
.list {
  min-height: 200px;
}
.card {
  background: #fff;
  box-shadow: 0 5px 10px #33333308;
  border-radius: 12px;
  padding: 20px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  width: 49%;
  box-sizing: border-box;
  &:nth-child(2n + 1) {
    margin-right: 2%;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    width: 100%;

    .cover {
      flex: 0 0 180px;
      margin-right: 20px;
      width: 180px;
      height: 101px;
      border-radius: 10px;
      overflow: hidden;
    }

    .detail {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      flex: auto;

      .text {
        font-size: 14px;
        color: #666;
        display: flex;
        width: 100%;
        span {
          flex: 1;
        }
      }

      .more {
        font-size: 14px;
        color: #175199;
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }
    }

    .handle {
      display: flex;
      align-items: center;
      width: 100%;
      p {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: 24px;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 1;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .btn {
      cursor: pointer;
      border-radius: 6px;
      line-height: 40px;
      font-weight: 500;
      border-radius: 6px;
      color: #a40001;
      background: #f7f7f7;
      padding: 0 30px;
      font-size: 14px;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }
  }
}
</style>

<style lang="scss"></style>
